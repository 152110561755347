.login-bg {
  background-image: url('../img/login-bg-1.jpg') !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  overflow: hidden;
}

//import react-table
@import '/src/static/scss/component/reactrable.css';

.btn {
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }
}

.DataTable-450 {
  .rt-tbody {
    max-height: calc(100vh - 450px);
  }
}

//box-shadow for all white boxes
.box-shadow {
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px #c7c7c7;
}

//main div of app
.wrapper {
  display: flex;
  align-items: flex-start;
  background: #f4f6f9;
}

//sidebar
.sidebar-container {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
  min-height: 100vh;
  background-color: white;
  box-shadow: 1px 0px 10px #7d7d7d80;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(153, 153, 153);
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(104, 104, 104);
  }

  //sidebar is collapsed
  &.closed {
    width: 4.5rem;
    overflow-y: unset;

    .nav-item {
      position: relative;

      p {
        display: none;
      }
    }

    .nav-treeview-container {
      display: none;
    }

    .nav-item.has-treeview:hover .nav-treeview-container {
      display: block;
      position: absolute;
      padding: 0 0.5rem;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);

      ul.nav.nav-treeview {
        display: block;
        background: #f3f3f3;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 10px #a2a1a1;

        .nav-link {
          padding: 0.75rem;
        }
      }
    }

    a.nav-link {
      text-align: center;
    }

    a.nav-link.active {
      color: #17a2b8;
    }
  }

  //end for closed

  .sidebar {
    height: auto !important;
    margin-top: 0 !important;
    padding: 0 !important;

    .image {
      height: 5rem;
      display: flex;
      align-items: center;
    }

    nav {
      margin-top: 1em;

      .nav-item {
        width: 100%;

        .nav-link {
          width: 100%;
          margin: 0;
          padding: 0.75rem 0.5rem;
          border-radius: 0;
          color: #51565a;

          &:hover,
          &.active {
            background-color: #f3f3f3;
            border-right: 3px solid #17a2b8;
            color: #17a2b8;
          }
        }
      }

      .nav-sidebar > .nav-item .nav-icon.fas {
        font-size: 1.2rem;
      }

      .nav.nav-treeview {
        margin-bottom: 0.5rem;
        background: #f3f3f3;

        .nav-link {
          display: flex;
          align-items: center;

          p {
            display: inline-block;
            white-space: nowrap !important;
            text-overflow: ellipsis !important;
            overflow: hidden !important;
          }

          &:hover,
          &.active {
            border-top-left-radius: 0.5em;
            border-bottom-left-radius: 0.5em;
          }
        }
      }
    }
  }

  i.fa-chevron-circle-right,
  i.fa-chevron-circle-left {
    display: block;
    margin: 0 auto 2rem auto;
    color: #4ea4b9;
    cursor: pointer;
  }
}

// main content div

.sidebar-container.closed ~ .content-wrapper {
  width: calc(100% - 4.5rem);
  margin-left: 4.5rem !important;
}

.content-wrapper {
  position: relative;
  min-height: 100vh !important;
  margin-top: 0 !important;
  margin-left: 220px !important;
  padding-bottom: 0 !important;
  width: calc(100% - 220px);
  transition: all 0.1s ease-in-out !important;

  .main-header {
    position: absolute !important;
    left: 1.5rem !important;
    right: 1.5rem !important;
    top: 0.5rem !important;
    margin-left: 0px !important;
    padding: 0.5rem 1rem;
    border: none;
  }

  .main-content {
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    top: 4.5rem;
    bottom: 4rem;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(153, 153, 153);
      border-radius: 0.5rem;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgb(104, 104, 104);
    }

    .content-header {
      padding: 1rem 0;
    }

    .card-header {
      border-radius: 0.5rem 0.5rem 0 0;
      background-color: #127054;
    }

    .nav-link {
      &:hover {
        color: #17a2b8;
      }
    }
  }

  footer {
    position: absolute;
    bottom: 0.5rem;
    left: 1.5rem;
    right: 1.5rem;
    padding: 0.5rem;
    background-color: white;
  }
}

//table
.ReactTable {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  .rt-tbody::-webkit-scrollbar {
    width: 7px;
  }

  .rt-tbody::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .rt-tbody::-webkit-scrollbar-thumb {
    background: rgb(153, 153, 153);
    border-radius: 0.5rem;
  }

  .rt-tbody::-webkit-scrollbar-thumb:hover {
    background: rgb(104, 104, 104);
  }

  .rt-th {
    padding: 0.5rem !important;
  }

  .rt-td {
    padding: 0.75rem;
  }

  .pagination-bottom {
    padding: 0.75rem 1rem;

    select {
      border: none;
      background: transparent;
    }
  }
}

//modal
.modal-content {
  border: none;
  border-radius: 0.5rem;
}

//card header
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card-tools {
    margin-left: auto;
    margin-right: 0;
  }
}

//add button
.add-button {
  border: 1px solid #17a2b8;

  &:hover {
    background-color: white;
    color: #17a2b8;
  }
}

//pdf button
.pdf-button {
  background-color: white;
  color: red;

  &:hover {
    background-color: red;
    color: white;
  }
}

//excel button
.excel-button {
  background-color: white;
  color: #1d6f42;

  &:hover {
    background-color: #1d6f42;
    color: white;
  }
}

//upload button
.upload-button {
  border: 1px solid #1d6f42;
  background-color: #1d6f42;
  color: white;

  &:hover {
    background-color: white;
    color: #1d6f42;
  }
}

//reset button
.reset-button {
  display: inline-block;
  border: 1px solid #28a745;

  &:hover {
    background-color: white;
    color: #28a745;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border: none;
}

//login page
.login-page {
  position: relative;
  background-color: #f1f1f1;
  background-image: url('../img/login-background.svg');
  background-position: 80% 50%;
  background-repeat: no-repeat;

  img {
    position: absolute;
    top: 5%;
    left: 5%;
  }

  form {
    margin-bottom: 5%;
    margin-right: 40%;

    h1 {
      font-size: 3rem;
      color: #4ea4b9;
      font-weight: 600;
    }

    p {
      font-size: 1.1rem;
      margin-bottom: 3rem;
    }

    #login-email,
    #login-password {
      display: flex;
      flex-direction: column;
      min-width: 350px;
      position: relative;
    }

    select {
      height: 56px;
      width: 100%;
      border: none;
      border-radius: 0.5rem;
    }

    button {
      height: 45px;
      width: 100%;
    }

    #login-email input,
    #login-password input {
      width: 100%;
      height: 56px;
      padding: 14px 16px 0 10px;
      outline: 0;
      border: none;
      border-radius: 0.5rem;
      background: #fff;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;
    }

    #login-email label,
    #login-password label {
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      padding: 0 12px;
      color: #999;
      pointer-events: none;
      position: absolute;
      transform: translate(0, 17px) scale(1);
      transform-origin: top left;
      transition: all 0.2s ease-out;
    }

    #login-email:focus-within label,
    #login-password:focus-within label {
      transform: translate(0, 5px) scale(0.75);
    }

    #login-email .active-label,
    #login-password .active-label {
      transform: translate(0, 10px) scale(0.75);
    }
  }
}

//export button

.export-button {
  position: relative;

  & > .btn {
    display: block;
    margin-left: auto;
    border: 1px solid #2f2e41;
    background-color: #2f2e41;
    color: white;
    border-radius: 0.5rem;

    &:hover {
      color: #0e1946;
      background-color: white;
    }
  }
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 400;
  margin: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100%;
  padding: 6px;
  border: 1px solid lightgray;
  border-radius: 1px;
}

.react-datepicker-custom-class {
  width: 20.5rem;
}

.react-datepicker-popper {
  z-index: 10000000;
}

.red-border-datepicker {
  border: 1px solid red !important;
}

.border-bottom-only-date-picker {
  border-bottom: 1px solid lightgray !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
}
