@import 'static/scss/index';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;600;700;900&display=swap');

.ReactTable .rt-td {
  overflow: hidden;
  word-break: break-word !important;
  word-wrap: break-word;
}

td,
th {
  overflow: hidden;
  padding: 8px !important;
  word-break: break-word;
  text-align: left !important;
}

.ReactTable .rt-td {
  white-space: normal;
}

.form-group {
  margin-bottom: 0.5rem;
}

.ReactTable .rt-th {
  white-space: normal;
}

.ReactTable.-striped .rt-tr.-odd {
  display: flex;
  align-items: center !important;
}

.ReactTable.-striped .rt-tr.-even {
  display: flex;
  align-items: center !important;
}

.bg-highlight {
  background-color: #bdd5ea;
}

.bg-highlight-green {
  background-color: #b3ffae;
}

.react-select-prefix {
  &__control {
    min-height: 32px !important;
    max-height: 32px !important;
  }

  &__value-container {
    min-height: 32px !important;
    max-height: 32px !important;
  }

  &__indicators {
    min-height: 32px !important;
    max-height: 32px !important;
  }
}

.editable-table td,
th {
  overflow: visible;
  word-break: normal;
}

.editable-table {
  overflow-x: scroll;
  overflow-y: visible !important;
}

.currency-input-small {
  display: block;
  width: 100%;
  // height: calc(1.5em + 0.75rem + 2px);
  padding: 0.225rem 0.45rem;
  font-size: 1rem;
  font-weight: 400;
  // line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
